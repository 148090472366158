import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from './pages/About';
import Blog from './pages/Blog';
import Home from './pages/Home';
import Countdown from './pages/Countdown';
import Demos from './pages/Demos';
import NoPage from './pages/NoPage';
import { initSettings } from './actions/SettingsActions';
import { CommonContext } from './contexts/CommonContext';

function Pages({ initSettings }) {
  const themeContext = useContext(CommonContext);

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (settings) {
      initSettings(settings);
      themeContext.changeTheme(settings.theme);
    }
  }, [initSettings, themeContext]);

  useEffect(() => {
    if (themeContext.theme === 'dark') {
      document.body.classList.remove('light');
    } else {
      document.body.classList.add('light');
    }
  }, [themeContext.theme]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />}>
          <Route path=":publishDate" element={<Blog />} />
        </Route>
        <Route path="/countdown" element={<Countdown />} />
        <Route path="/demos" element={<Demos />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  settings: state.settings,
});

const mapDispatchToProps = dispatch => (
  bindActionCreators({ initSettings }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Pages);