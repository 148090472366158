import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaMarkup = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Cerebral Cereal",
    "url": "https://cerebral-cereal.com",
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schema)}
      </script>
    </Helmet>
  );
};

export default SchemaMarkup;